import axios from "axios";
import Qs from "qs";
import {Notification} from "element-ui";
import $store from "@/store";

const instance = axios.create({
    // baseURL: "/api",
    baseURL: "http://brainapi.bangq1.com",
    // baseURL: "http://192.168.1.173:8080",
});
// 配置拦截器
instance.interceptors.request.use(async (config) => {
    // 批量处理post请求
    if (!config.data && config.method === "post") {
        config.data = {}
    }
    if (config.method === "post" && config.data.request == "form") {
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        config.data = Qs.stringify(config.data);
    }
    if (localStorage.getItem('token')) {
        config.headers['token'] = localStorage.getItem('token');
    }
    /*自定义请求头*/
    config.headers['username'] = encodeURIComponent("bangQiFuWuYun");
    if ($store.state.tokens != '') {
        config.headers['Authorization'] = $store.state.tokens;
    }
    /*允许所有来源访问 */
    config.headers['Access-Control-Allow-Origin'] = '*';
    /*这个属性主要是设置浏览器优先使用什么模式来渲染页面的。代码IE=edge告诉IE使用最新的引擎渲染网页,这个在需要兼容IE浏览器的情况下都可以加上这句话*/
    config.headers['http-equiv'] = 'X-UA-Compatible';
    config.headers['content'] = 'IE=EDGE';
    return config;
}), (err) => {
    return Promise.reject(err);
};
// 响应拦截器
instance.interceptors.response.use(
    (res) => {
        if (res.data.code == 403) {
            Notification.error({
                title: "错误",
                message: res.data.content,
            });
            history.go(-1);
            return Promise.reject(res);
        }
        return res.data;
    },
    (err) => {
        console.log(
            Notification.error({
                title: "错误",
                message: "服务器错误！",
            })
        );
        return Promise.reject(err);
    }
);
export default instance;
